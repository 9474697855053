<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>
        <!-- 查询Tab -->
        <div class="searchdiv">
            <div class="searchbox">
                <div class="searchinput">
                    <input v-model="searchKeywords" type="text" maxlength="20" placeholder="搜资讯 / 找大学 / 查专业 / 看职业" v-on:keyup.enter="handleEnterSearch" />
                    <div class="btn" @click="searchStart"><i class="iconfont">&#xe6a2;</i>搜 索</div>
                </div>
                <el-tabs v-model="tabactive" type="card">
                    <el-tab-pane label="资讯" name="1">
                        <div class="tips">找到<span class="tips-number">{{ informationBox.Total==null?0:informationBox.Total }}</span>条相关内容</div>
                        <div class="newslist">
                            <div class="ndata">
                                <div class="abox" v-for="(item,index) in informationBox.Data" :key="index" @click.prevent="toDetails(item, 'information')">
                                    <div class="tbox">
                                        <div class="tit" style="height:86px" v-html="item.newTitle"></div>
                                        <div class="mbox">
                                            <span><i class="iconfont">&#xe615;</i>{{item.Source}}</span>
                                            <span><i class="iconfont">&#xe673;</i>{{item.Source}}</span>
                                            <span><i class="iconfont">&#xe665;</i>{{item.ReleaseDate.substring(0,16)}}</span>
                                            <span><i class="iconfont">&#xe650;</i>{{item.ViewCountBase+item.ViewCountReal}} 次阅读</span>
                                        </div>
                                    </div>
                                    <div class="nimg">
                                        <div class="img" :style="{background:'url('+item.CoverImageFileUrl+') no-repeat center center / cover'}"></div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                            <!-- <div class="pages" @click.prevent="pagesbtn()">
                                <span>{{pagetxt}}</span>
                            </div> -->
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="大学" name="2">
                        <div class="tips">找到<span class="tips-number">{{ collegeBox.Total==null?0:collegeBox.Total }}</span>条相关内容</div>
                        <div class="newslist">
                            <div class="ndata">
                                <div class="abox" v-for="(item,index) in collegeBox.Data" :key="index" @click.prevent="toDetails(item, 'college')">
                                    <div class="tbox">
                                        <div class="tit" style="height:43px" v-html="item.newTitle"></div>
                                        <div class="tag">
                                            <span v-for="(it,ind) in item.TagNames.split(',')" :key="ind">{{it}}</span>
                                        </div>
                                        <div class="mbox">
                                            <span><i class="iconfont">&#xe615;</i>{{item.ProvinceName}} · {{item.CityName}}</span>
                                            <span><i class="iconfont">&#xe679;</i>{{item.NatureStr}} · {{item.CategoryNames}}</span>
                                            <span><i class="iconfont">&#xe661;</i>{{item.EducationLevelStr}}</span>
                                            <span><i class="iconfont">&#xe953;</i>{{item.Tel}}</span>
                                        </div>
                                    </div>
                                    <div class="nimg school">
                                        <div class="img" :style="{background:'url('+item.LogoUrl+') no-repeat center center / cover'}"></div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                            <!-- <div class="pages" @click.prevent="pagesbtn()">
                                <span>{{pagetxt}}</span>
                            </div> -->
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="专业" name="3">
                        <div class="tips">找到<span class="tips-number">{{ specialityBox.Total==null?0:specialityBox.Total }}</span>条相关内容</div>
                        <div class="newslist">
                            <div class="ndata">
                                <div class="abox" v-for="(item,index) in specialityBox.Data" :key="index" @click.prevent="toDetails(item, 'speciality')">
                                    <div class="tbox">
                                        <div class="tit" v-html="item.newTitle"></div>
                                        <div class="mbox">
                                            <span><i class="iconfont">&#xe634;</i>代码：{{item.SpecialtyCode}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="nimg">
                                        <div class="img" :style="{background:'url('+item.CoverImageFileUrl+') no-repeat center center / cover'}"></div>
                                    </div> -->
                                    <div class="clear"></div>
                                </div>
                            </div>
                            <!-- <div class="pages" @click.prevent="pagesbtn()">
                                <span>{{pagetxt}}</span>
                            </div> -->
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="职业" name="4">
                        <div class="tips">找到<span class="tips-number">{{ occupationBox.Total==null?0:occupationBox.Total }}</span>条相关内容</div>
                        <div class="newslist">
                            <div class="ndata">
                                <div class="abox" v-for="(item,index) in occupationBox.Data" :key="index" @click.prevent="toDetails(item, 'occupation')">
                                    <div class="tbox">
                                        <div class="tit" v-html="item.newTitle"></div>
                                        <div class="mark">{{item.ProfessionDesc}}</div>
                                        <div class="mbox">
                                            <span><i class="iconfont">&#xe634;</i>代码：{{item.InterestCode}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="nimg">
                                        <div class="img" :style="{background:'url('+item.CoverImageFileUrl+') no-repeat center center / cover'}"></div>
                                    </div> -->
                                    <div class="clear"></div>
                                </div>
                            </div>
                            <!-- <div class="pages" @click.prevent="pagesbtn()">
                                <span>{{pagetxt}}</span>
                            </div> -->
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import API from '@/api/config'
import { getStore } from '@/utils/utils';
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
export default {
    components: {
        PHeader,
        PFooter
    },
    data() {
        return {
            tabactive: "1",
            searchText: '',

            activeTab: '0',
            searchKeywords: '',
            informationPageIndex: 1,
            informationPageSize: 10,
            collegePageIndex: 1,
            collegePageSize: 10,
            specialityPageIndex: 1,
            specialityPageSize: 10,
            occupationPageIndex: 1,
            occupationPageSize: 10,
            informationBox: {},
            collegeBox: {},
            specialityBox: {},
            occupationBox: {},

            provindeId: ''
        }
    },
    watch:{
        $route(to,from){
            this.searchKeywords = this.$route.query.keywords
            if (this.searchKeywords) {
                this.initialize()
            }
        }
    },
    mounted() {
        //关键字赋值
        this.searchKeywords = this.$route.query.keywords

        // 省份参数
        let temp = getStore('OrgProvince')
        if(temp) {
            this.provindeId = JSON.parse(temp).ProvinceId
        }

        if (this.searchKeywords) {
            this.initialize()
        }

    },
    methods: {
        //查询Enter键按下
        handleEnterSearch(){
            if (this.searchKeywords) {
                this.initialize()
            }
        },
        //详情
        toDetails(item, flag) {

            let url
            switch (flag) {
                case 'information':
                    url = this.$router.resolve({
                        path: '/newsdetail',
                        query: { id: item.Id }
                    })
                    break;
                case 'college':
                    url = this.$router.resolve({
                        path: '/university-detail',
                        query: { id: item.ID }
                    })
                    break;
                case 'speciality':
                    url = this.$router.resolve({
                        path: '/specialty-detail',
                        query: { SpecialtyCode: item.SpecialtyCode }
                    })
                    break;
                case 'occupation':
                    url = this.$router.resolve({
                        path: '/profession-detail',
                        query: { ID: item.ID }
                    })
                    break;
                default:
                    break;
            }
            window.open(url.href)
        },
        //搜索
        searchStart() {
            if (this.searchKeywords.trim()) {
                this.informationPageIndex = 1
                this.collegePageIndex = 1
                this.specialityPageIndex = 1
                this.occupationPageIndex = 1
                this.initialize()
            }
        },
        //分页
        pageSizeChange(value, flag) {
            if (flag === 'information') {
                this.informationPageIndex = 1
                this.informationPageSize = value
                this.getInformation()
            } else if (flag === 'college') {
                this.collegePageIndex = 1
                this.collegePageSize = value
                this.getCollege()
            } else if (flag === 'speciality') {
                this.specialityPageIndex = 1
                this.specialityPageSize = value
                this.getSpeciality()
            } else if (flag === 'occupation') {
                this.occupationPageIndex = 1
                this.occupationPageSize = value
                this.getOccupation()
            }
        },
        currentPageChange(value, flag) {
            if (flag === 'information') {
                this.informationPageIndex = value
                this.getInformation()
            } else if (flag === 'college') {
                this.collegePageIndex = value
                this.getCollege()
            } else if (flag === 'speciality') {
                this.specialityPageIndex = value
                this.getSpeciality()
            } else if (flag === 'occupation') {
                this.occupationPageIndex = value
                this.getOccupation()
            }
        },
        initialize() {
            //资讯
            this.getInformation()
            //大学
            this.getCollege()
            //专业
            this.getSpeciality()
            //职业
            this.getOccupation()
        },
        getInformation() {
            this.activeLoad()
            API.Web.GetNewsInfoList({
                NewsTitle: this.searchKeywords,
                PageIndex: this.informationPageIndex,
                PageSize: this.informationPageSize,
                ExamProvinceId: this.provindeId
            }).then(res => {
                this.highlightKeywords('information', res.Data.Data)

                this.informationBox = res.Data
                // scrollTo(0, 200)
                this.activeLoad(true)
            })
        },
        getCollege() {
            this.activeLoad()
            API.School.SchoolListBySchoolName({
                SchoolName: this.searchKeywords,
                PageIndex: this.collegePageIndex,
                PageSize: this.collegePageSize,
            }).then(res => {
                this.highlightKeywords('college', res.data.Data)
                this.collegeBox = res.data
                // scrollTo(0, 200)
                this.activeLoad(true)
            })
        },
        getSpeciality() {
            this.activeLoad()
            API.Specialty.SpecialtySearch({
                Name: this.searchKeywords,
                PageIndex: this.specialityPageIndex,
                PageSize: this.specialityPageSize
            }).then(res => {
                this.highlightKeywords('speciality', res.data.Data)
                this.specialityBox = res.data
                // scrollTo(0, 200)
                this.activeLoad(true)
            })
        },
        getOccupation() {
            this.activeLoad()
            API.Profession.ProfessionSearchByName({
                Name: this.searchKeywords,
                PageIndex: this.occupationPageIndex,
                PageSize: this.occupationPageSize,
            }).then(res => {
                this.highlightKeywords('occupation', res.data.Data)
                this.occupationBox = res.data
                // scrollTo(0, 200)
                this.activeLoad(true)
            })
        },
        //标重关键字
        highlightKeywords(type, query) {
            let temp, name
            switch (type) {
                case 'information':
                    name = 'NewsTitle'
                    break
                case 'college':
                    name = 'SchoolName'
                    break
                case 'speciality':
                    name = 'SpecialtyName'
                    break
                case 'occupation':
                    name = 'ProfessionName'
                    break
                default:
                    break
            }
            query.forEach(item => {
                temp = item[name].split(this.searchKeywords)
                this.$set(item, 'newTitle', temp.join("<span class='highlight-words'>" + this.searchKeywords + "</span>"))
            })
            temp = null
            name = null
        },

        // loading
        activeLoad(e) {
            let loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading'
            })

            if(e) {
                loading.close()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.searchdiv {
    width: 100%;
    min-height: 600px;

    .searchbox {
        max-width: 1420px;
        margin: 0px auto 20px;
        .searchinput {
            margin: 0px 20px;
            height: 80px;

            input {
                width: calc(100% - 132px);
                height: 44px;
                border: 1px solid #ddd;
                border-radius: 3px;
                line-height: 44px;
                line-height: 1;
                text-indent: 20px;
                color: #666;
                font-size: 14px;
                margin-top: 17px;
                background-color: #fff;
            }

            .btn {
                display: inline-block;
                width: 120px;
                height: 46px;
                border-radius: 3px;
                background-color: rgba(var(--themecolor), 0.7);
                text-align: center;
                line-height: 46px;
                color: #fff;
                font-size: 16px;
                margin-left: 10px;
                cursor: pointer;

                i {
                    font-size: 16px;
                    margin-right: 5px;
                }
            }

            .btn:hover {
                background-color: rgba(var(--themecolor), 0.85);
            }
        }
        .el-tabs {
            margin: 0px 20px;
            background-color: #fff;

            .el-tabs__item {
                background-color: #666;
            }
        }
        .tips {
            font-size: 20px;
            color: #666;
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-indent: 20px;
            .tips-number {
                color: rgb(var(--themecolor));
                margin: 0px 5px;
            }
        }
        .newslist{
            width: 100%;
            float: left;
            padding-top: 10px;
            overflow: hidden;
            .ndata{
                width: 100%;
            }
            .pages{
                width: 100%;
                min-height: 50px;
                line-height: 50px;
                color: #666;
                font-size: 16px;
                text-align: center;
                letter-spacing: 1px;
                margin-bottom: 10px;
                user-select: none;
                .iconfont{
                    font-size: 50px;
                    display: block;
                    margin-top: 40px;
                    color: #9b9b9b;
                }
            }
            .pages:hover{
                color: rgb(var(--themecolor));
                cursor: pointer;
            }
            .abox{
                max-height: 141px;
                margin: 0px 0px 10px 10px;
                overflow: hidden;
                background-color: #fff;
                border: 1px solid #ebebeb;
                border-radius: 5px;
                .tbox{
                    float: left;
                    width: calc(100% - 250px);
                    .tit{
                        font-size: 18px;
                        color: #333;
                        letter-spacing: 1px;
                        margin: 12px 20px 0px;
                        line-height: 30px;
                        max-height: 86px;
                        overflow: hidden;
                        transition: color 0.3s;
                        -webkit-transition: color 0.3s;
                    }
                    .tag{
                        margin-left: 20px;
                        height: 43px;
                        overflow: hidden;
                        span{
                            padding: 2px 10px;
                            border-radius: 30px;
                            background-color: rgba(var(--themecolor), 0.1);
                            color: #888;
                            font-size: 12px;
                            margin-right: 15px;
                        }
                    }
                    .mbox{
                        height: 30px;
                        margin-left: 20px;
                        margin-top: 10px;
                        i{
                            font-size: 14px;
                            color: #666;
                            margin-right: 5px;
                        }
                        span{
                            color: #888;
                            font-size: 12px;
                            margin-right: 20px;
                        }
                    }
                }
                .nimg{
                    float: right;
                    width: 208px;
                    height: 100px;
                    border-radius: 5px;
                    overflow: hidden;
                    margin: 20px;
                    .img{
                        width: 100%;
                        height: 100%;
                        transition: all 0.3s;
                        -webkit-transition: all 0.3s;
                    }
                }
                .school{
                    width: 100px;
                }
                .mark{
                    color: #666;
                    font-size: 13px;
                    margin-left: 20px;
                    margin-top: 10px;
                }
            }
            .abox:hover{
                cursor: pointer;
            }
            .abox:hover .tbox .tit{
                color: rgb(var(--themecolor));
            }
            .abox:hover .nimg .img{
                transform:scale(1.08);
                -webkit-transform:scale(1.08);
            }
            .abox:hover .nimg.school .img{
                transform:none;
                -webkit-transform:none;
                opacity: 0.8;
            }
        }
    }
}
</style>
<style>
.searchdiv .searchbox .el-tabs{
    padding-bottom: 20px;
}
.searchdiv .searchbox .el-tabs .el-tabs__item {
    background-color: rgba(var(--themecolor), 0.15);
    color: #555;
    font-size: 16px;
    letter-spacing: 2px;
    height: 42px;
    line-height: 42px;
    padding: 0px 60px;
}

.searchdiv .searchbox .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border-left: 0px;
}

.searchdiv .searchbox .el-tabs .el-tabs__item:hover {
    color: rgb(var(--themecolor));
}

.searchdiv .searchbox .el-tabs .el-tabs__item.is-active {
    background-color: #fff;
    color: rgb(var(--themecolor));
    font-weight: bold;
}

.searchdiv .searchbox .el-tabs .el-tabs__item.is-focus {
    box-shadow: none;
    border-radius: 0px;
}

.searchdiv .searchbox .el-tabs--card>.el-tabs__header .el-tabs__item {
    border-left: 1px solid #ddd;
}

.searchdiv .searchbox .el-tabs--card>.el-tabs__header .el-tabs__item:first-child {
    border-left: 0px;
}

.searchdiv .searchbox .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
    padding-left: 60px;
}

.searchdiv .searchbox .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:last-child {
    padding-right: 60px;
}
.highlight-words {
    color: red;
}
</style>