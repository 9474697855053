import {service} from '@/api/config'
import qs from 'qs'
import axios from 'axios';
export default {
    Web:{

        /****************** 公共 相关 ******************/
        GetProvinceOptions(data){
            return axios.post('/api/Options/GetProvinceOptions', data);
        },
        
        /****************** 登录注册相关 ******************/
        // 网站配置 /api/Config/WebConfig
        WebConfig(data){
            return axios.post('/api/Config/WebConfig', data);
        },
        // 用户登录 /api/Auth/Login
        Login(data){
            return axios.post('/api/Auth/Login', data);
        },
        LoginStatus(data){
            return axios.post('/api/Auth/LoginStatus', data);
        },

        // 手机验证码登录/注册 /api/Auth/ValidateCodeLoginOrRegister
        ValidateCodeLoginOrRegister(data){
            return axios.post('/api/Auth/ValidateCodeLoginOrRegister', data);
        },
        // 注销登录 /api/Auth/Loginout
        Loginout(data){
            return axios.post('/api/Auth/Loginout', data);
        },
        // 忘记密码 /api/Auth/UpdatePassword
        UpdatePassword(data){
            return axios.post('/api/Auth/UpdatePassword', data);
        },
        // 获取滑块拼图验证码 /api/Auth/GetCaptcha
        GetCaptcha(data){
            return axios.post('/api/Auth/GetCaptcha', data);
        },
        // 验证滑块拼图验证码 /api/Auth/CheckCaptcha
        CheckCaptcha(data){
            return axios.post('/api/Auth/CheckCaptcha', data);
        },
        // 发送手机验证码 /api/Auth/SendValidateCode
        SendValidateCode(data){
            return axios.post('/api/Auth/SendValidateCode', data);
        },
        //验证手机验证码 /api/Auth/CheckValidateCode
        CheckValidateCode(data){
            return axios.post('/api/Auth/CheckValidateCode', data);
        },

        /****************** 网站轮播 相关 ******************/
        GetAdList(data){
            return axios.post('/api/Web/GetAdList', data);
        },

        /****************** 通知公告 相关 ******************/
        GetNoticeList(data){
            return axios.post('/api/Web/GetNoticeList', data);
        },

        /****************** 工具箱 相关 ******************/
        GetToolBoxList(data){
            return axios.post('/api/Web/GetToolBoxList', data);
        },

        /****************** 网站新闻 相关 ******************/
        //获取新闻类别
        GetNewsCategoryList(data){
            return axios.post('/api/Web/GetNewsCategoryList', data);
        },
        //获取新闻资讯
        GetNewsInfoList(data){
            return axios.post('/api/Web/GetNewsInfoList', data);
        },
        //获取新闻资讯详情
        GetNewsInfoDetail(data){
            return axios.post('/api/Web/GetNewsInfoDetail', data);
        },
        //增加新闻资讯浏览量
        AddNewsInfoCount(data){
            return axios.post('/api/Web/AddNewsInfoCount', data);
        },

        /****************** 视频 相关 ******************/
        //获取视频类别
        GetVideoCategoryList(data){
            return axios.post('/api/Web/GetVideoCategoryList', data);
        },
        //获取视频
        GetVideoList(data){
            return axios.post('/api/Web/GetVideoList', data);
        },
        //增加视频浏览量
        AddVideoCount(data){
            return axios.post('/api/Web/AddVideoCount', data);
        },
        
        /****************** 专家 相关 ******************/
        //获取专家
        GetTeacherList(data){
            return axios.post('/api/Web/GetTeacherList', data);
        },
        //获取专家详情
        GetTeacherDetail(data){
            return axios.post('/api/Web/GetTeacherDetail', data);
        },
        //专家预约
        TeacherReserve(data){
            return axios.post('/api/Web/TeacherReserve', data);
        },
        
        /****************** 升学资讯 相关 ******************/
        //获取升学资讯
        UpgradeNewsList(data){
            return axios.post('/api/Career/UpgradeNewsList', data);
        },
        UpgradeNewsDetail(data){
            return axios.post('/api/Career/UpgradeNewsDetail', data);
        },
        
        /****************** 友情链接 相关 ******************/
        //获取友情链接
        GetFriendLinkList(data){
            return axios.post('/api/Web/GetFriendLinkList', data);
        },
        //获取单页内容
        GetContentDetail(data){
            return axios.post('/api/Web/GetContentDetail', data);
        },

        /****************** 帮助中心 相关 ******************/
        //获取帮助类别
        GetHelpCategoryList(data){
            return axios.post('/api/Web/GetHelpCategoryList', data);
        },
        //获取帮助信息
        GetHelpList(data){
            return axios.post('/api/Web/GetHelpList', data);
        },
        //获取帮助信息
        GetHelpTopList(data){
            return axios.post('/api/Web/GetHelpTopList', data);
        },
        //获取帮助信息详情
        GetHelpDetail(data){
            return axios.post('/api/Web/GetHelpDetail', data);
        },

        /****************** 高考倒计时 相关 ******************/
        // 高考倒计时 /api/Web/GetExamCountdown
        GetExamCountdown(data){
            // return axios.post('/api/Web/GetExamCountdown', data);
            let value = qs.stringify(data)
            return service({
                url: '/api/Web/GetExamCountdown',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: value
            })
        },

        /****************** 产品 相关 ******************/
        // 产品列表
        GetProductList() {
            return axios.post('/api/Web/GetProductList')
        },
        // 产品详情
        GetProductDetail(data) {
            return axios.post('/api/Web/GetProductDetail', data)
        },

        /****************** 脚本注入 相关 ******************/
        // 脚本注入
        GetScriptInjectList() {
            return axios.post('/api/Web/GetScriptInjectList')
        },
    }
}