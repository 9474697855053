// 路由登录白名单
export const whiteList = ['/personal','/examinee','/openvip','/setpwd'];

//省份数据
export const ProvinceData = [
	{value:"1",text:"安徽",char:"A"},
	{value:"2",text:"北京",char:"B"},
	{value:"3",text:"重庆",char:"C"},
	{value:"4",text:"福建",char:"F"},
	{value:"5",text:"广东",char:"G"},
	{value:"6",text:"广西",char:"G"},
	{value:"7",text:"甘肃",char:"G"},
	{value:"8",text:"贵州",char:"G"},
	{value:"9",text:"河北",char:"H"},
	{value:"10",text:"河南",char:"H"},
	{value:"11",text:"海南",char:"H"},
	{value:"12",text:"湖北",char:"H"},
	{value:"13",text:"湖南",char:"H"},
	{value:"14",text:"黑龙江",char:"H"},
	{value:"15",text:"吉林",char:"J"},
	{value:"16",text:"江苏",char:"J"},
	{value:"17",text:"江西",char:"J"},
	{value:"18",text:"辽宁",char:"L"},
	{value:"19",text:"内蒙古",char:"N"},
	{value:"20",text:"宁夏",char:"N"},
	{value:"21",text:"青海",char:"Q"},
	{value:"22",text:"上海",char:"S"},
	{value:"23",text:"四川",char:"S"},
	{value:"24",text:"山东",char:"S"},
	{value:"25",text:"山西",char:"S"},
	{value:"26",text:"陕西",char:"S"},
	{value:"27",text:"天津",char:"T"},
	{value:"28",text:"新疆",char:"X"},
	{value:"29",text:"云南",char:"Y"},
	{value:"30",text:"浙江",char:"Z"}
];

