<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 个人中心主页 -->
        <div class="usercenter">
            <div class="uccontent">
                <div class="userhead">
                    <div class="headback">
                    <div class="head">
                        <i class="iconfont" v-show="UserData.Student.Sex==true" :style="{'background':'url('+require('../../assets/images/men.svg')+') no-repeat center center / cover'}"></i>
                        <i class="iconfont" v-show="UserData.Student.Sex==false" :style="{'background':'url('+require('../../assets/images/women.svg')+') no-repeat center center / cover'}"></i>
                    </div>
                    <div class="info">
                        <div class="mark1">
                            <span class="name">昵称：{{UserData.NickName}}</span>
                            <span v-if="UserData.Student.StudentName">姓名：{{UserData.Student.StudentName}}</span>
                            <span v-if="UserData.Exam.ExamProvinceName">{{UserData.Exam.ExamProvinceName}}</span>
                            <span v-if="UserData.Exam.ExamYear">{{UserData.Exam.ExamYear}}年高考</span>
                        </div>
                        <div class="mark2">
                            <div v-if="ProductData==''" class="ovipbtn" @click.prevent="$router.push('/openvip')"><i class="iconfont">&#xe62e;</i>开通 VIP 会员</div>
                            <el-popover v-else class="ovipbtn" v-for="(item,index) in ProductData" :key="index" placement="top-start" title="有效期至：" width="200" trigger="hover" :content="item.ExpireAt">
                                <el-button slot="reference"><i class="iconfont buy">&#xe62e;</i>{{item.ProductName}}</el-button>
                            </el-popover>
                            <div class="unav" @click.prevent="$router.push('/userinfo')"><i class="iconfont">&#xe672;</i><span>基本信息</span></div>
                            <div class="unav" @click.prevent="$router.push('/examinee')"><i class="iconfont">&#xe60f;</i><span>高考设置</span></div>
                            <div class="unav" @click.prevent="$router.push('/test-list')"><i class="iconfont">&#xe60b;</i><span>我的测评</span></div>
                            <div class="unav" @click.prevent="$router.push('/wish-list')"><i class="iconfont">&#xe86d;</i><span>我的志愿表</span></div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="usercon">
                    <div class="userleft">
                        <div class="ulbox" v-for="(item,index) in headnavdata1" :key="index">
                            <div class="tit"><i></i>{{item.title}}</div>
                            <div class="txt">
                                <div class="tt" v-show="it.isset==false" :class="(headnavactive==it.id?'active':'')+(it.type==1?' bg':'')" @click.prevent="headnavbtn(it.url)" v-for="(it,ind) in item.clist" :key="ind"><i class="iconfont" v-html="it.icon"></i>{{ it.title }}</div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                    <div class="userright">
                        <router-view />
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import { getStore } from '@/utils/utils';
import { Message} from 'element-ui';
import API from "@/api/config";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            headnavactive:'',
            headnavdata1:[],
            headnavdata:[
                {id:'1',title:'用户信息',clist:[
                    {id:'1-1',type:0,title:'基本信息',icon:'&#xe672;',url:'/userinfo',isset:false},
                    {id:'1-2',type:0,title:'高考设置',icon:'&#xe60f;',url:'/examinee',isset:false},
                    {id:'1-3',type:0,title:'密码设置',icon:'&#xe7c2;',url:'/setpwd',isset:false},
                    {id:'1-4',type:0,title:'账号设置',icon:'&#xe7c2;',url:'/setaccount',isset:false},
                    {id:'1-5',type:0,title:'开通VIP会员',icon:'&#xe62e;',url:'/openvip',isset:false},
                ]},
                {id:'2',title:'生涯规划',clist:[
                    // {id:'2-1',type:0,title:'专业意向',icon:'&#xe642;',url:''},
                    // {id:'2-2',type:0,title:'院校意向',icon:'&#xe647;',url:''},
                    {id:'2-3',type:0,title:'我的测评',icon:'&#xe60b;',url:'/test-list',isset:false},
                    // {id:'2-4',type:0,title:'我的选科',icon:'&#xe86d;',url:''},
                    {id:'2-5',type:0,title:'我的志愿表',icon:'&#xe86d;',url:'/wish-list',isset:false}
                ]},
            ],
            UserData:{
                NickName:'',
                Student:{
                    Sex:null,
                    StudentName:''
                },
                Exam:{
                    ExamProvinceName:'',
                    ExamYear:''
                }
            },
            HasLoginName:false,
            HasSetPwd:true,
            ProductData:[]
        }
    },
    updated(){
        this.refreshusercenter();// 刷新选中路由
    },
    mounted(){
        this.refreshusercenter();// 刷新选中路由
        this.GetUserProducts();// 获取用户授权产品
        this.GetUserStudent();// 获取学生及考试信息
    },
    methods:{
        //刷新选中路由
        refreshusercenter(){
            for(let i=0;i<this.headnavdata.length;i++){
                let obj = this.headnavdata[i].clist.find(res=>res.url==this.$route.path);
                if(obj){
                    this.headnavactive=obj.id;
                    break;
                }
            }
        },
        //左侧菜单点击
        headnavbtn(url){
            if(url){
                this.$router.push(url);
            }
            else{
                Message("功能开发中……");
            }
        },
        // 获取学生及考试信息
        GetUserStudent(){
            API.Personal.GetUserStudent().then(res => {
                if(res.Code===0){
                    if(res.Data!=null){
                        this.HasLoginName = res.Data.HasLoginName;
                        if(this.HasLoginName==true){
                            this.headnavdata[0].clist = this.headnavdata[0].clist.filter(f=>f.title!=='账号设置');
                        }
                        else{
                            this.headnavdata[0].clist = this.headnavdata[0].clist.filter(f=>f.title!=='密码设置');
                        }
                        this.headnavdata1 = this.headnavdata;
                        this.UserData.NickName = res.Data.NickName;
                        if(res.Data.Exam!=null){
                            this.UserData.Exam=res.Data.Exam;
                        }
                        if(res.Data.Student!=null){
                            this.UserData.Student=res.Data.Student;
                        }
                    }
                }
            });
        },
        // 获取用户授权产品
        GetUserProducts(){
            API.Personal.GetUserProducts().then(res => {
                if(res.Code===0&&res.Data!=null){
                    this.ProductData = res.Data;
                }
            }); 
        }
    }
}
</script>
<style lang="less" scoped>
.usercenter{
    max-width: 1420px;
    margin: auto;
    .uccontent{
        margin: 10px 20px 0;
        /* 个人中心头部 */
        .userhead{
            width: 100%;
            height: 120px;
            background:url(../../assets/images/userback.jpg) no-repeat center center / cover;
            user-select: none;
            .headback{
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.3);
            }
            .head{
                width: 66px;
                height: 66px;
                overflow: hidden;
                border-radius: 70px;
                border: 2px solid rgb(var(--themecolor));
                float: left;
                margin-left: 30px;
                margin-top: 24px;
                background-color: #fff;
                i{
                    display: block;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                }
            }
            .info{
                float: right;
                width: calc(100% - 134px);
                .mark1{
                    margin-top: 24px;
                    span{
                        color: #f3f3f3;
                        font-size: 14px;
                        margin-right: 20px;
                    }
                    .name{
                        color: #f3f3f3;
                        font-size: 16px;
                    }
                }
                .mark2{
                    margin-top: 20px;
                    .ovipbtn{
                        height: 26px;
                        line-height: 26px;
                        background-color: rgba(var(--themecolor),0.85);
                        border-radius: 20px;
                        color: #fff;
                        float: left;
                        padding: 0px 15px;
                        margin-right: 20px;
                        position: relative;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                        font-size: 15px;
                        i{
                            font-size: 16px;
                            float: left;
                            margin-top: 1px;
                            margin-right: 4px;
                        }
                        .el-button{
                            background: none;
                            border: 0px;
                            padding: 0;
                            font-size:12px;
                            border-radius:0;
                            color: #fff;
                            font-size: 15px;
                        }
                    }
                    .ovipbtn:hover{
                        cursor: pointer;
                        background-color: rgba(var(--themecolor),1);
                    }
                    .unav{
                        float: left;
                        height: 26px;
                        line-height: 26px;
                        color: #f3f3f3;
                        margin-right: 20px;
                        font-size: 14px;
                        i{
                            float: left;
                            margin-right: 5px;
                            color: #d8d6d6;
                            font-size: 17px;
                        }
                    }
                    .unav:hover{
                        opacity: 0.85;
                        cursor: pointer;
                    }
                }
            }
        }
        .usercon{
            width: 100%;
            margin-top: 10px;
            margin-bottom: 20px;
            /* 个人中心左侧 */
            .userleft{
                width: 270px;
                min-height: 500px;
                background-color: #fff;
                border: 1px solid #ddd;
                float: left;
                user-select: none;
                .ulbox{
                    width: 100%;
                    border-bottom: 1px solid #e4e4e4;
                    padding-bottom: 20px;
                    .tit{
                        color: #333;
                        font-size: 16px;
                        height: 30px;
                        line-height: 30px;
                        border-bottom: 1px solid #e6e6e6;
                        padding: 10px 0px;
                        i{
                            width: 5px;
                            height: 20px;
                            margin: 5px 10px 0px 10px;
                            background-color: rgb(var(--themecolor));
                            display: block;
                            float: left;
                        }
                    }
                    .txt{
                        color: #555;
                        font-size: 14px;
                        margin: 0px 24px;
                        .tt{
                            width: 50%;
                            float: left;
                            margin-top: 25px;
                        }
                        .tt.bg{
                            width: 100%;
                        }
                        .tt:hover,.tt.active{
                            cursor: pointer;
                            color: rgb(var(--themecolor));
                            i{
                                color: rgb(var(--themecolor));
                            }
                        }
                        i{
                            color: #666;
                            font-size: 20px;
                            margin-right: 8px;
                            float: left;
                        }
                    }
                    
                }
            }
            /* 个人中心右侧 */
            .userright{
                width: calc(100% - 284px);
                background-color: #fff;
                border: 1px solid #ddd;
                float: right;
                min-height: 500px;
            }
        }
    }
}
</style>