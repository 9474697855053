import {service} from '@/api/config'
import qs from 'qs'

let bodyHeaders = {
    "Content-Type": "application/json; charset=utf-8"
}

export default {

    Wish: {


        WishProvinceAll() {
            return service({
                url: '/api/Wish/WishProvinceAll'
            })
        },
    
        WishYear() {
            return service({
                url: '/api/Wish/WishYear',
                headers: bodyHeaders
            })
        },
        WishProvince(data) {
            return service({
                url: '/api/Wish/WishProvince',
                headers: bodyHeaders,
                data
            })
        },
        NewExamBatchList(data) {
            return service({
                url: '/api/Wish/NewExamBatchList',
                headers: bodyHeaders,
                data
            })
        },
    
        TableHeadYear(data) {
            return service({
                url: '/api/Wish/TableHeadYear',
                headers: bodyHeaders,
                data
            })
        },
    
        MySelfSearch(data) {
            return service({
                url: '/api/Wish/MySelfSearch',
                headers: bodyHeaders,
                data
            })
        },
    
        MySelfForecast(data) {
            return service({
                url: '/api/Wish/MySelfForecast',
                headers: bodyHeaders,
                data
            })
        },
    
        MySelfSearchForecast(data) {
            return service({
                url: '/api/Wish/MySelfSearchForecast',
                headers: bodyHeaders,
                data
            })
        },
    
        MySelf(data) {
            return service({
                url: '/api/Wish/MySelf',
                headers: bodyHeaders,
                data
            })
        },
    
        SpecialtyList(data) {
            return service({
                url: '/api/Wish/SpecialtyList',
                headers: bodyHeaders,
                data
            })
        },
    
        SpecialtyListForecast(data) {
            return service({
                url: '/api/Wish/SpecialtyListForecast',
                headers: bodyHeaders,
                data
            })
        },
    
        BatchList(data) {
            return service({
                url: '/api/Wish/BatchList',
                headers: bodyHeaders,
                data
            })
        },
    
        SpecialtyFirstStatForecast(data) {
            return service({
                url: '/api/Wish/SpecialtyFirstStatForecast',
                headers: bodyHeaders,
                data
            })
        },
    
        SpecialtyFirstListForecast(data) {
            return service({
                url: '/api/Wish/SpecialtyFirstListForecast',
                headers: bodyHeaders,
                data
            })
        },
    
        SpecialtyFirstStat(data) {
            return service({
                url: '/api/Wish/SpecialtyFirstStat',
                headers: bodyHeaders,
                data
            })
        },
    
        SpecialtyFirstList(data) {
            return service({
                url: '/api/Wish/SpecialtyFirstList',
                headers: bodyHeaders,
                data
            })
        },
    
        
    
        SchoolFirstStat(data) {
            return service({
                url: '/api/Wish/SchoolFirstStat',
                headers: bodyHeaders,
                data
            })
        },
    
        SchoolFirstList(data) {
            return service({
                url: '/api/Wish/SchoolFirstList',
                headers: bodyHeaders,
                data
            })
        },
        
        SchoolFirstStatForecast(data) {
            return service({
                url: '/api/Wish/SchoolFirstStatForecast',
                headers: bodyHeaders,
                data
            })
        },
    
        SchoolFirstListForecast(data) {
            return service({
                url: '/api/Wish/SchoolFirstListForecast',
                headers: bodyHeaders,
                data
            })
        },

        NewExamScoreLineForecastExportColumnList() {
            return service({
                url: '/api/Wish/NewExamScoreLineForecastExportColumnList'
            })
        },

        NewExamScoreLineForecast(data) {
            return service({
                url: '/api/Wish/NewExamScoreLineForecast',
                headers: bodyHeaders,
                data
            })
        },
    
        NewExamTableHeadYear(data) {
            return service({
                url: '/api/Wish/NewExamTableHeadYear',
                headers: bodyHeaders,
                data
            })
        },
    
        NewExamScoreLineSuggestGroup(data) {
            return service({
                url: '/api/Wish/NewExamScoreLineSuggestGroup',
                headers: bodyHeaders,
                data
            })
        },
    
        NewExamScoreLine(data) {
            return service({
                url: '/api/Wish/NewExamScoreLine',
                headers: bodyHeaders,
                data
            })
        },
    
        NewExamSchoolScoreLineForecastGroup(data) {
            return service({
                url: '/api/Wish/NewExamSchoolScoreLineForecastGroup',
                headers: bodyHeaders,
                data
            })
        },
    
        NewExamSchoolScoreLineForecast(data) {
            return service({
                url: '/api/Wish/NewExamSchoolScoreLineForecast',
                headers: bodyHeaders,
                data
            })
        },
    
        NewExamSchoolScoreLineSpecialtyForecast(data) {
            return service({
                url: '/api/Wish/NewExamSchoolScoreLineSpecialtyForecast',
                headers: bodyHeaders,
                data
            })
        },
    
        NewExamSchoolScoreLineSuggestGroup(data) {
            return service({
                url: '/api/Wish/NewExamSchoolScoreLineSuggestGroup',
                headers: bodyHeaders,
                data
            })
        },
    
        NewExamSchoolScoreLine(data) {
            return service({
                url: '/api/Wish/NewExamSchoolScoreLine',
                headers: bodyHeaders,
                data
            })
        },
    
        NewExamSchoolScoreLineSpecialty(data) {
            return service({
                url: '/api/Wish/NewExamSchoolScoreLineSpecialty',
                headers: bodyHeaders,
                data
            })
        },
        NewExamScoreLineForecastGroup(data) {
            return service({
                url: '/api/Wish/NewExamScoreLineForecastGroup',
                headers: bodyHeaders,
                data
            })
        },
    },

    User: {

        CheckUserStudentComplate() {
            return service({
                url: '/api/User/CheckUserStudentComplate',
            })
        },

        SaveWish(data) {
            return service({
                url: '/api/User/SaveWish',
                headers: bodyHeaders,
                data
            })
        },
    
        WishDetail(data) {
            return service({
                url: '/api/User/WishDetail',
                headers: bodyHeaders,
                data
            })
        },
    
        GetWishList(data) {
            return service({
                url: '/api/User/GetWishList',
                headers: bodyHeaders,
                data
            })
        },
    
        GetStudentList(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Student/GetStudentList',
                data: value
            })
        },
    
        GetStudentDetail(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Student/GetStudentDetail',
                data: value
            })
        },
    
        GetStudentExamList(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Student/GetStudentExamList',
                data: value
            })
        },
    
        GetStudentExamDetail(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Student/GetStudentExamDetail',
                data: value
            })
        },
    
        UpdateStudentExam(data) {
            return service({
                url: '/api/Student/UpdateStudentExam',
                headers: bodyHeaders,
                data
            })
        },
    
        DelWish(data) {
            return service({
                url: '/api/User/DelWish',
                headers: bodyHeaders,
                data
            })
        },
    
        GetStudentList(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Student/GetStudentList',
                data: value
            })
        },
    
        GetStudentDetail(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Student/GetStudentDetail',
                data: value
            })
        },
    
        AddStudent(data) {
            return service({
                url: '/api/Student/AddStudent',
                headers: bodyHeaders,
                data
            })
        },
    
        UpdateStudent(data) {
            return service({
                url: '/api/Student/UpdateStudent',
                headers: bodyHeaders,
                data
            })
        },
    
        DelStudent(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/Student/DelStudent',
                data: value
            })
        },

        SaveWishSpecialtySchool(data) {
            return service({
                url: '/api/User/SaveWishSpecialtySchool',
                headers: bodyHeaders,
                data
            })
        },
    
        WishSpecialtySchoolDetail(data) {
            return service({
                url: '/api/User/WishSpecialtySchoolDetail',
                headers: bodyHeaders,
                data
            })
        },

        // 志愿详情
        TestList(data) {
            return service({
                url: '/api/User/TestList',
                data
            })
        },
        TestMBTIInfo(data) {
            return service({
                url: '/api/user/TestMBTIInfo',
                data
            })
        },
        TestHollandInfo(data) {
            return service({
                url: '/api/User/TestHollandInfo',
                data
            })
        },
        TestPersonalityInfo(data) {
            return service({
                url: '/api/User/TestPersonalityInfo',
                data
            })
        },
        TestSelSubjectInfo(data) {
            return service({
                url: '/api/User/TestSelSubjectInfo',
                data
            })
        },
        TestUpgradePathInfo(data) {
            return service({
                url: '/api/User/TestUpgradePathInfo',
                data
            })
        },

        // 卡号确认/api/User/GetCardDetail
        
        GetCardDetail(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/User/GetCardDetail',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                data: value
            })
        },

        UpdateUserExamFinalScore(data) {
            let value = qs.stringify(data)
            return service({
                url: '/api/User/UpdateUserExamFinalScore',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                data: value
            })
        }

    },

}